.customSelect {
  display: flex;
  justify-content: center;
  position: relative;

  .container {
    width: 150px;
  }

  .disabled {
    pointer-events: none;
    opacity: 0.7;
  }

  button.dropDown {
    background: #fff;
    border: 1px solid #ced4da;
    border-radius: 3px;
    transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s;
    width: 100% !important;
    padding: 8px;
    position: relative;
    text-align: left;
    color: #495057;

    &:hover {
      background: #fff;
      border: 1px solid #ced4da;
      color: #495057;
      cursor: pointer;
    }
  }

  .options {
    display: none;
    list-style: none;
    padding: 4px 0;
    margin-top: -4px;
    overflow: auto;
    background: #fff;
    border: 0;
    color: #495057;
    border-radius: 3px;
    box-shadow: 0 2px 4px -1px #00000033, 0 4px 5px 0 #00000023, 0 1px 10px 0 #0000001E;
  }

  .show {
    display: block;
    position: absolute;
    background: white;
    width: 100%;
    height: 200px;
    z-index: 100;
  }

  .options .optionItem {
    padding: 6px 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .fieldName {
      display: flex;
      justify-content: space-between;
    }

    .fieldType {
      font-size: 10px;
    }

    .deleteOptionBtn {
      background: transparent;
      border: 1px solid transparent;
      width: 20px;
      height: 20px;
    }
  }

  .options .optionItem:active,
  .options .optionItem:focus,
  .options .optionItem:hover,
  .options .optionItem[aria-selected="true"] {
    background: #e9ecef;
    cursor: pointer;
  }

  .addNewField {
    width: 100%;
    padding: 8px;
    background: #98c355;
    border: 1px solid #98c355;
    color: #fff;
  }

}