$gutter: 1rem;
$headerHeight: 67px;
$homeHeaderHeight: 100px;

@import 'node_modules/primeflex/primeflex.scss';
@import 'datepicker-special-week-numbers/dist/react-datepicker.css';
@import './assets/scss/variables';
@import './assets/scss/fonts';

* {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  min-width: 768px;
  min-height: 500px;
  font-size: $fontSize;
  font-family: $fontFamily;
}

$scrollSize: 7px;
::-webkit-scrollbar {
  width: $scrollSize;
  height: $scrollSize;
}

::-webkit-scrollbar-track {
  background: #ffffff;
  border-radius: $scrollSize;
}

::-webkit-scrollbar-thumb {
  background: $primaryDarkColor;
  border-radius: $scrollSize;
}

::-webkit-scrollbar-thumb:hover {
  background: $primaryDarkerColor;
}

body {
  .overlay_panel .p-overlaypanel-content .p-listbox {
    border: none !important;
    width: inherit !important;
  }

  .auth0-lock-container .auth0-lock-header-logo {
    width: 100% !important;
  }

  .p-dialog-mask {
    .p-datatable-scrollable-body {
      .p-row-editor-init.p-link {
        border-radius: 50%;
        height: 2.357rem;
        width: 2.357rem;
        color: #ffffff;
        background: #2196f3;
        border: 1px solid #2196f3;
        box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2), 0 3px 4px rgba(0, 0, 0, 0.14);
      }

      .p-link.p-row-editor-init:enabled:hover {
        color: #ffffff;
        background: #2196f3;
      }

      .p-button-rounded.p-button-warning.p-button-icon-only {
        box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2), 0 3px 4px rgba(0, 0, 0, 0.14);
      }
    }

    .confirmation-content {
      display: flex;
      flex-direction: row;

      i {
        font-size: 2rem;
      }
    }
  }

  .p-panel {
    .p-panel-content {
      background: rgba(255, 255, 255, 0.8);
      border-bottom-right-radius: 10px;
      border-bottom-left-radius: 10px;
    }

    .p-panel-header {
      background: rgba(255, 255, 255, 0.8);
      border-top-right-radius: 10px;
      border-top-left-radius: 10px;
      font-size: 18px;
    }
  }
}

.p-galleria-enter-done {
  width: 550px !important;
}

.p-galleria-content {
  width: 85% !important;
}

#root {
  height: 100% !important;
}

.App {
  height: 100%;

  .top-nav {
    height: $headerHeight;
    margin: 0;
    border-bottom: 1px solid #dee2e6;
  }

  .home-top-nav {
    height: $homeHeaderHeight;
    margin: 0;
    border-bottom: 1px solid #dee2e6;
  }

  .content {
    display: flex;
    flex-direction: row;
    height: calc(100% - #{$headerHeight});
  }

  .list-generic {
    position: relative;
    flex: 1;
    height: 100%;
    overflow: hidden;

    .table-generic {
      position: relative;
      border: solid 1px lightgray;
      border-radius: 4px;
      margin: 0 1rem;
      height: calc(100% - 115px);

      &.with-paginator {
        height: calc(100% - 170px);
      }
    }
  }

  .side-filters {
    padding: 0 10px 5px;
    height: 100%;
    display: flex;
    flex-direction: column;

    .filtersInputs {
      display: flex;
      flex: 1;
      flex-direction: column;
      overflow-y: auto;
      min-height: min-content;
      padding-right: 5px;
      margin-right: -5px;
    }
  }

  .p-datatable-scrollable-wrapper {
    height: 100%;
  }

  .p-datatable-scrollable-view {
    height: 100%;
  }

  .p-datatable-scrollable-body {
    height: calc(100% - 100px);

    .p-row-editor-init.p-link {
      border-radius: 50%;
      height: 2.357rem;
      width: 2.357rem;
      color: #ffffff;
      background: #2196f3;
      border: 1px solid #2196f3;
      box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2), 0 3px 4px rgba(0, 0, 0, 0.14);
    }

    .p-link.p-row-editor-init:enabled:hover {
      color: #ffffff;
      background: #2196f3;
    }

    .p-button-rounded.p-button-warning.p-button-icon-only {
      box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2), 0 3px 4px rgba(0, 0, 0, 0.14);
    }
  }

  .p-datatable-unfrozen-view .p-rowgroup-header-name {
    visibility: hidden;
  }

  .p-multiselect-label {
    color: $text-grey;
  }

  .p-multiselect .p-multiselect-label.p-placeholder {
    color: $text-grey;
    font-weight: initial;
  }

  .p-button-icon-only.p-datepicker-trigger {
    background-color: white;
    border: 1px solid lightgray;
  }

  .p-button.p-button-info {
    background: #7ebdfc;
    border: 1px solid #7ebdfc;
  }

  .p-button-rounded.p-button-info.p-button-outlined.p-button-icon-only {
    background-color: transparent;
    color: #0288d1;
    border: 1px solid;
  }

  .p-button.p-button-success {
    background: #98c355;
    border: 1px solid #98c355;
  }

  .p-button.p-button-danger {
    background: #e86a4a;
    border: 1px solid #e86a4a;
  }

  .p-button.p-button-help {
    background: #9d3fc2;
    border: 1px solid #9d3fc2;
  }

  .p-datatable-scrollable-body,
  .p-datatable-scrollable-header,
  .p-datatable-scrollable-footer {
    width: auto !important;
  }

  .p-datatable.p-datatable-sm .p-datatable-header {
    padding: 8px 10px;
  }

  .p-datatable .p-datatable-thead > tr > th {
    font-weight: 500;
  }

  .p-progress-spinner {
    position: relative;
    margin: auto;
    width: 150px;
    height: 150px;
    display: flex;
  }

  .p-rowgroup-header {
    background: $lighter-grey !important;
  }

  .p-breadcrumb .p-menuitem-text:hover {
    text-decoration: underline;
  }

  .generic-list-message {
    position: absolute;
    top: calc(50% + 35px);
    left: 0;
    right: 0;
    overflow-wrap: anywhere;
    margin-left: 1rem;
    margin-right: 1rem;

    &.export,
    &.import {
      top: 45%;
      margin-left: 15rem;
    }

    h2,
    h3 {
      color: $text-grey;
    }
  }
}

.react-datepicker__input-container .picker-error {
  border: 1px solid red !important;
}

.react-datepicker-wrapper {
  display: flex;

  .react-datepicker__close-icon {
    padding: 6px;
    position: static;
  }

  .react-datepicker__close-icon::after {
    background-color: #607d8b;
    opacity: 0.6;
  }
}

.react-datepicker {
  .react-datepicker__week-number {
    color: black;
    font-weight: 800;
  }

  .react-datepicker__day-name:first-child {
    font-weight: 800;
  }
}

.p-dialog .p-dialog-content {
  padding: 0 1.5rem 0 1.5rem;
}

.p-inputtext.p-component:disabled {
  background-color: $lighter-grey;
}

.margin-r-5 {
  margin-right: 5px;
}

.margin-l-0 {
  margin-left: 0;
}

.pad-r-0 {
  padding-right: 0;
}

.pad-t-0 {
  padding-top: 0;
}

.new-line {
  white-space: pre-line;
}

.p-dialog .p-dialog-footer {
  padding: 1rem 1.5rem 1.5rem 1.5rem;
}

.logs
  > div.p-datatable-scrollable-wrapper
  > div.p-datatable-scrollable-view
  > div.p-datatable-scrollable-header
  > div
  > table
  > thead
  > tr
  > th
  > span.p-sortable-column-badge {
  display: none;
}

.trialPlantList
  > div.p-datatable-scrollable-wrapper
  > div.p-datatable-scrollable-view
  > div.p-datatable-scrollable-body
  > table
  > tbody
  > tr
  > td,
.employeeList
  > div.p-datatable-scrollable-wrapper
  > div.p-datatable-scrollable-view
  > div.p-datatable-scrollable-body
  > table
  > tbody
  > tr
  > td {
  padding: 0 0.5rem;
}

.fc.fc-media-screen.fc-direction-ltr.fc-theme-standard {
  margin: 1rem;
}

.fc.fc-theme-standard .fc-toolbar .fc-button .fc-icon-pi {
  font-family: 'PrimeIcons', serif !important;
  text-indent: 0;
  font-size: 1rem;
}

.rowEditor-editButton-hidden {
  button.p-row-editor-init.p-link {
    visibility: hidden;
  }
}

.sharedTrialBlock
  > div.p-field.p-grid.p-ai-start
  > div
  > div
  > div.p-multiselect-label-container
  > .p-multiselect-label {
  display: flex;
  flex-wrap: wrap;
}

.sharedTrialBlock
  > div.p-field.p-grid.p-ai-start
  > div
  > div
  > div.p-multiselect-label-container
  > .p-multiselect-label
  > .p-multiselect-token {
  display: flex;
  margin-bottom: 3px;
}

table
  > thead
  > tr
  > th.p-sortable-disabled.p-resizable-column
  > span.p-column-title {
  vertical-align: middle;
}

.p-toast-message-error > .p-toast-message-content > .p-toast-message-text {
  max-width: 245px;
}

#profile_popup_menu {
  width: auto;
}

.trialForm {
  #location > span.p-placeholder,
  #trialManager > span.p-placeholder,
  #logFrequency > span.p-placeholder {
    color: #495057;
  }
}

// used for ability to show tooltip on disabled buttons
.p-disabled {
  pointer-events: all;
}

.justify-content-end {
  display: flex;
  justify-content: flex-end;
}

.justify-content-center {
  display: flex;
  justify-content: center;
}

#container {
  position: relative;
  display: flex;
  overflow: hidden;
  user-select: none;
  touch-action: none;
  box-sizing: border-box;
}

.draggable {
  user-select: none;
  touch-action: none;
}

.resize-drag {
  box-sizing: border-box;
}

.resize-container {
  width: 100%;
  height: 240px;
}

.systemStyle td:nth-last-child(2),
.systemStyle td:last-child {
  pointer-events: none;
  opacity: 0.5;
}

td.p-dt-tooltip > .hintDetails {
  width: 100%;
  border: none;
  background: none;

  &:hover,
  &:focus {
    background: transparent;
    border-color: transparent;
    box-shadow: none;
  }
}

.confirmDialog {
  background-color: $primaryTextColor;
  width: 450px;
  max-width: 90%;
}

.formDialog {
  width: $formDialogWidth;
  max-width: $formDialogMaxWidth;
}

.importErrorsTable {
  max-height: 90vh;
  overflow: auto;
  .importInfoBlock {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 10px;
    .importInfoMessage {
      font-weight: bold;
    }
  }
  table {
    border-collapse: collapse;
    tr {
      width: 100%;
      td,
      th {
        border: 1px solid red;
        padding: 3px;
      }
    }
  }
}

.colorPicker > div > input {
  width: 28px !important;
}

.statuses-table {
  .p-component-overlay {
    background-color: transparent;
  }
}

.p-fluid .p-fileupload .p-button {
  aspect-ratio: 1;
}

@media only screen and (max-width: $width-md) {
  .App {
    width: 100vw;
    height: 100%;

    .tabPaginator > span:first-child {
      display: none;
    }
  }
}
@media only screen and (max-width: $width-sm) {
  .App {
    .p-datatable.p-datatable-sm .p-datatable-header {
      padding: 4px;
    }
    .tabPaginator > span {
      display: none;
    }
    .fc .fc-toolbar {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
    }
  }
}
