@import "../../../assets/scss/variables.scss";

.applicationList {

  .tableHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .tableLabel {
      display: flex;
      flex-direction: row;
      margin: 0;

      .tableName {
        margin: 4px 15px 0 0;
        font-weight: 500;
      }
    }
  }

  .editorWidth {
    width: 120px;
  }

  .linkStyle {
    cursor: pointer;
    color: $light-blue;
    text-decoration: underline;
  }

  .linkStyle:hover {
    text-decoration: none;
  }

}

.dialog {
  width: $deleteDialog-width;
}