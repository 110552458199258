@import '../../../assets/scss/variables';

.plantFModal {
  width: $formDialogWidth;
  max-width: $formDialogMaxWidth;

  .readOnly {
    &:hover {
      border-color: #ced4da !important;
    }

    &:active {
      box-shadow: none;
    }

    &:focus {
      box-shadow: none !important;
      border-color: #ced4da !important;
    }
  }

  .pdfLinkWrapper {
    display: inline-block;
    width: 100%;
    height: 34px;
    font-size: 1rem;
    background: #ffffff;
    padding: 0.5rem 0.5rem;
    border: 1px solid #ced4da;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s,
      box-shadow 0.2s;
    appearance: none;
    border-radius: 3px;
  }

  .plantImage {
    width: 100%;
    height: 100%;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2), 0 3px 6px rgba(0, 0, 0, 0.25);
  }
}
