@import "../../../assets/scss/variables";

.statusFilter {
  display: flex;
  flex-direction: row;

  .statusIcon {
    margin: 4px 8px;
    font-size: 0.75em;
  }
}
