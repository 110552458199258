.labelInfo {
  .template {
    min-width: 200px
  }

  .nameInput {
    min-width: 300px
  }

  .description {
    input {
      min-width: 400px
    }
  }
  .saveButton {
    padding: 0.5rem 2rem;
  }
}