.statusesTable {
  border: solid 1px lightgray;
  border-radius: 4px;
  margin-bottom: 20px;

  .statusIcon {
    font-size: 1.5em;
  }

  .tableHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .tableLabel {
      display: flex;
      flex-direction: row;
      margin: 0;

      button {
        margin-right: 8px;
      }
    }
  }
}

.formDataDialog {
  width: 380px;
  background-color: #fff;

  button {
    margin-left: 13px;
  }
}
.colorPicker > div > input {
  width: 28px !important;
}
