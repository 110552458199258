@import "../../../assets/scss/variables";

.dashboardItem {
  display: flex;
  flex-direction: column;
  padding: 10px 0;
  border-bottom: 1px solid #dee2e6;

  a {
    text-decoration: none;
  }

  .taskWrapper {
    text-decoration: none;
    display: flex;
    align-items: flex-start;
    padding: 0 7px;
  }

  .taskTitle {
    display: inline-block;
    width: 50%;
    color: $dark-blue;

    .taskLink {
      color: var(--blue-400);
      font-weight: normal;
      font-size: 14px;
      text-decoration: underline;

      &:hover {
        text-decoration: underline;
      }
    }

    .taskManager {
      min-height: 18px;
    }
  }

  .taskContent {
    display: inline-block;
    width: 50%;
    color: $dark-blue;
  }

  .itemContent {
    display: flex;
    padding: 5px 0;
    color: $dark-blue;

    .name {
      width: 50%;
      font-size: 14px;
      text-overflow: ellipsis;
      color: var(--blue-400);
      text-decoration: underline;

      &:hover {
        text-decoration: underline;
      }
    }

    .region,
    .username {
      width: calc(50% - 30px);
    }
  }

  &:hover {
    background: #e9ecef;
    cursor: pointer;
  }
}

.noClickable {
  pointer-events: none;
  opacity: 0.7;
}