@import '../../../../../assets/scss/variables';

.header {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;

  h3 {
    margin: 8px;
  }

  .multiselect {
    width: 15rem;
    height: fit-content;
  }

  .groupByDropdown {
    width: 10rem;
  }

  .buttonsGroup {
    display: flex;
    gap: 10px;

    button {
      height: fit-content;
    }
  }

  .hideElement {
    display: none;
  }
}

.selectAll > div > div {
  span {
    width: 15px;
  }
  &::after {
    content: 'All';
    margin-left: 10px;
  }
}

.selectAll > span {
  content: 'All';
  margin-left: 10px;
}

@media only screen and (max-width: $width-sm) {
  .header {
    .multiselect {
      width: 12rem;
    }
  }
}
