@import '../../../../../../assets/scss/variables';
.imageField {
  width: 100%;
  border: 1px solid #dee2e6;
  border-radius: 3px;
  overflow: hidden;
  .header {
    width: 100%;
    background: #f8f9fa;
    padding: 1rem;
    color: #495057;
    border-bottom: 1px solid #dee2e6;
    position: relative;
    .hidden {
      opacity: 0;
      height: 0;
      width: 0;
      line-height: 0;
      overflow: hidden;
      padding: 0;
      margin: 0;
    }
    .progressBar {
      position: absolute;
      right: calc(50% - 135px);
      top: calc(50% - 4px);
      .progress {
        width: 250px;
        height: 7px;
        position: relative;
      }
    }
  }
  .content {
    width: 100%;
    background: #ffffff;
    padding: 2rem 1rem;
    color: #495057;
    .emptyTemplate {
      width: 100%;
      i {
        font-size: 3em;
        border-radius: 50%;
        background-color: var(--surface-b);
        color: var(--surface-d);
      }
      span {
        font-size: 1em;
        color: var(--text-color-secondary);
      }
    }
  }
}
.invalid {
  border-color: $dangerColor !important;
}
