.labelEditor {
  width: calc(100% - 2rem);
  margin-left: 1rem;
  margin-right: 1rem;

  .notClickable {
    pointer-events: none;
  }

  .menuMargin {
    margin: 0 5px;
  }

  .fontBold {
    font-weight: bolder;
  }

  .fontNormal {
    font-weight: normal;
  }

  .hideElement {
    display: none !important;
  }

  .editorWrapper {

    .editorPanel {
      position: relative;
      padding: 30px 0 0 30px;

      .verticalRuler {
        position: absolute;
        left: 0;
        top: 30px;
        height: calc(100% - 30px);
        width: 30px;
      }

      .horizontalRuler {
        position: absolute;
        top: 0;
        left: 30px;
        width: calc(100% - 30px);
        height: 30px;
      }

      .panel {

        .panelHeader {
          display: flex;
          align-items: flex-end;
          padding: 15px 0;

          .panelPreview {
            position: relative;
            width: 300px;
            height: 200px;
            border: 1px solid grey;

            i {
              font-size: 50px;
              position: absolute;
              top: calc(50% - 25px);
              left: calc(50% - 25px);
              color: grey;
            }
          }

          .panelMenu {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 10px;
            background-color: #e9ecef;

            input {
              width: 60px
            }

            button {
              span.pi-trash {
                font-size: 1.5rem;
              }
            }
          }
        }

        .panelEditArea {
          display: flex;
          height: 450px;
          margin-bottom: 15px;
          border: 1px solid grey;

          .leftSide {
            width: 100%;
            height: 100%;
            overflow: scroll;

            .outerContainer {
              width: fit-content;
              height: fit-content;
              border: 1px solid grey;
              background-color: #FFFFFF;
            }

            .labelItem {
              padding: 0;
              color: #000;
              position: relative;

              span {
                margin-right: 3px;
              }
            }
          }
        }
      }

    }
  }

  .buttonsBlock {
    display: flex;
    justify-content: flex-end;
    margin: 10px 0;

    button:last-child {
      margin-left: 10px;
    }
  }

  .toggleButton {
    background-color: transparent;
    color: #607D8B;
    border: 1px solid;

    &:hover {
      background: rgba(96, 125, 139, 0.04) !important;
      color: #607D8B !important;
      border: 1px solid !important;
    }
  ;
  }

}
