@import "../../../../assets/scss/variables";
.sectionFormDialog {
  width: $formDialogWidth;
  max-width: $formDialogMaxWidth;

  .button {
    margin: 1rem 0.5rem;
    &:last-child{
      margin-right: 0;
    }
  }
}


