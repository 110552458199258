.newLabel {
  display: flex;
}

.dialog {
  max-height: 100% !important;
  width: 90% !important;
}

.buttonsBlock {
  margin: 10px 0 !important;

  button:last-child {
    margin-left: 10px;
  }
}
