@import "../../../../assets/scss/variables";

.plantFormDialog {
  width: $formDialogWidth;
  max-width: $formDialogMaxWidth;

  .button {
    margin: 1rem 0.5rem;

    &:last-child {
      margin-right: 0;
    }
  }

  .plantImage {
    margin: 0 5px;
    border-radius: 5px;
    object-fit: contain;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2), 0 3px 6px rgba(0, 0, 0, 0.25);
  }

  .checkbox {
    padding-left: 14px;
    margin-bottom: 0;
  }

  .noTopPadding {
    padding-top: 0;
  }

  .noRightPadding {
    padding-right: 0;
  }
}
