@import '../../assets/scss/variables';

.landingPage {
  width: 100%;
  font-family: 'Montserrat Regular', 'Arial', sans-serif;
  font-size: 16px;

  .starcomsoft {
    font-weight: bold;
    color: $lpLightColor;
    text-decoration: none;
  }

  .onlyDesktop {
    display: block;
  }

  .sectionPadding {
    padding: 20px 140px;
  }

  .square {
    width: 100%;
    aspect-ratio: 1;
    position: relative;
    margin-bottom: 65px;
  }

  .ringHeader {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: -65px;
    font-size: 24px;
    font-weight: bold;
    width: 100%;
    height: 55px;
    margin: 10px 0 0 0;
    padding: 0 10px;
    text-align: center;
  }

  h2,
  h3,
  h4,
  h5,
  h6 {
    color: $lpHeaderColor;
    font-style: normal;
    font-family: 'Montserrat Bold', 'Arial', sans-serif;
    margin-bottom: 0;
    margin-top: 0;
  }

  h2 {
    font-size: 40px;
    line-height: 54px;
  }

  h4 {
    font-size: 24px;
    line-height: 28px;
    font-weight: bold;
    width: 100%;
    margin: 40px 0 20px 0;
    text-align: center;
  }

  h6 {
    font-size: 20px;
    line-height: 30px;
  }

  .plainText {
    color: $lpParagraphColor;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    margin-top: 10px;
  }

  .verticalLine {
    background-color: $lpLightColor;
    display: inline-block;
    width: 1px;
    height: 16px;
    margin: 0 5px;
  }

  button.greenButton {
    padding: 8px 40px;
    background-color: $lpButtonColor;
    border: 2px solid $lpButtonColor;
    border-radius: 3px;
    outline: none;
    box-shadow: 3px 5px 5px $lpShadowColor;

    span {
      color: $lpLightColor;
    }

    &:hover {
      background-color: $lpButtonColor;
      border: 2px solid $lpButtonColor;
      color: $lpLightColor;
      opacity: 0.8;
      box-shadow: 2px 4px 4px $lpShadowColor;
    }
  }

  .freeTrial {
    padding: 8px 40px;
    background-color: $lpLightColor;
    border-radius: 3px;
    outline: none;
    box-shadow: 3px 5px 5px #495057;
    border: 2px solid #72bb53;

    span {
      color: #72bb53;
    }

    &:hover {
      background-color: $lpLightColor !important;
      border: 2px solid #72bb53 !important;
      color: $lpButtonColor;
      opacity: 0.8;
      box-shadow: 2px 4px 4px $lpShadowColor;
    }
  }

  .golden {
    color: $lpGoldenColor;
  }

  .ultimate {
    position: relative;
    top: 0;
    left: 0;
    .ultimateMobileHeader {
      display: none;
      color: $lpHeaderColor;
      font-size: 24px;
      font-family: 'Montserrat Bold', 'Arial', sans-serif;
      padding: 0 15px;
    }

    .ultimateWrapper {
      background-color: $lpBackgroundColor;
      background-image: url('../../assets/img/yellowBlue_bg.png');
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      width: 100%;
      aspect-ratio: 1.8;

      .content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        color: $lpLightColor;
        position: absolute;
        top: 40px;
        left: 30px;

        .ultimateHeader {
          margin: 25px 0 10px;
          font-family: 'Montserrat Bold', 'Arial', sans-serif;
          font-weight: bold;
          font-size: 38px;
          line-height: 120%;
          text-shadow: -2px 4px 4px $lpShadowColor;
          max-width: 55vw;
        }

        .ultimateText {
          width: 500px;
          padding-left: 15px;
          font-weight: 400;
          font-size: 25px;
          font-family: 'Montserrat Regular', 'Arial', sans-serif;
          color: $lpLightColor;
          max-width: 40vw;
        }

        .buttonsGroup {
          display: flex;
          flex-direction: column;
          margin-left: 7vw;

          .demo {
            margin: 20px 0;
          }
        }
      }
    }

    .mobileContent {
      display: none;
      width: 100vw;
      padding: 0 10px;

      h4 {
        text-align: left;
        margin: 10px 0;
      }

      .mobileButtonsGroup {
        display: flex;
        justify-content: space-around;

        .greenButton,
        .freeTrial {
          padding: 8px 30px;
        }
      }
    }
  }

  .benefits {
    border-radius: 0;

    .benefitsInfo {
      padding: 0 80px;
      color: $lpGreyColor;
    }

    .grid {
      display: table;
      width: 100%;
      padding: 10px 120px;
      border: none;
      border-collapse: separate;
      border-spacing: 5px;
      color: $lpParagraphColor;
    }

    .gridTr {
      display: table-row;
    }

    .gridTd {
      display: table-cell;
      width: 50%;
      vertical-align: top;
      padding: 5px 37px;

      .item {
        position: relative;

        &::before {
          content: '';
          display: block;
          background: url('../../assets/img/leaf_colored.png') no-repeat;
          background-size: contain;
          position: absolute;
          top: 0;
          left: -30px;
          width: 23px;
          height: 23px;
        }
      }
    }
  }

  .rings {
    border-radius: 0;

    .cardWrapper {
      position: static;
      height: fit-content;
      display: flex;
      align-items: flex-start;
      padding-bottom: 0;

      .card {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        flex: 1;
        padding: 16px 0;
        left: 0;
        top: 0;

        p {
          padding: 0 5px;
        }

        .setup {
          background: url('../../assets/img/flowSetup.png') no-repeat center;
          background-size: contain;
        }

        .crops {
          background: url('../../assets/img/flowPlant.png') no-repeat center;
          background-size: contain;
        }

        .mobileApp {
          background: url('../../assets/img/flowMobile.png') no-repeat center;
          background-size: contain;
        }

        .share {
          background: url('../../assets/img/flowShare.png') no-repeat center;
          background-size: contain;
        }

        .visualisation {
          background: url('../../assets/img/flowVizualize.png') no-repeat center;
          background-size: contain;
        }

        .setupHeader {
          color: $lpSetupColor;
        }

        .cropsHeader {
          color: $lpCropsColor;
        }

        .mobileAppHeader {
          color: $lpMobileColor;
        }

        .shareHeader {
          color: $shareColor;
        }

        .visualHeader {
          color: $visualColor;
        }
      }
    }
  }

  .mobile {
    .mobileWrapper {
      display: flex;

      .mobileOuter {
        width: 40%;
        height: 100%;
        aspect-ratio: 1;
        border-radius: 50%;
        border: 2px solid black;
        padding: 10px;

        .mobileInner {
          width: 100%;
          height: 100%;
          border-radius: 50%;
          background: url('../../assets/img/mobile3.png') no-repeat center;
          background-size: contain;
          background-clip: content-box;
        }
      }

      .mobileInfo {
        width: 60%;

        .mobileText {
          padding-left: 15px;
        }

        .mobileList {
          padding-inline-start: 140px;
          margin-top: 30px;
        }
      }
    }

    .mobileListSmall {
      display: none;
    }

    .listItem {
      position: relative;
      padding: 10px 0;
      list-style-type: none;

      &::before {
        content: '';
        display: block;
        background: url('../../assets/img/leaf_colored.png') no-repeat;
        background-size: contain;
        position: absolute;
        top: 7px;
        left: -30px;
        width: 23px;
        height: 23px;
      }
    }
  }

  .visualize {
    .visualizeWrapper {
      display: flex;
      margin: 40px 0 60px;

      .visualizeInfo {
        width: 37%;
        padding: 0 40px 0 0;
      }

      .visualizeLogo {
        position: relative;
        background: url('../../assets/img/logscreenshot.png') no-repeat;
        background-size: contain;
        width: 63%;
        aspect-ratio: 2;

        &::before {
          content: '';
          display: block;
          background: url('../../assets/img/mobiletrialslog.png') no-repeat;
          background-size: contain;
          position: absolute;
          top: 60%;
          left: 32%;
          width: 22%;
          aspect-ratio: 0.6;
        }
      }
    }
  }

  .community {
    margin-top: 100px;
    width: 100%;
    aspect-ratio: 1.8;
    position: relative;

    h4 {
      position: relative;
    }

    .communityWrapper {
      display: flex;

      .communityOuter {
        position: relative;
        width: 50%;
        aspect-ratio: 1/1;

        .communityInner {
          position: absolute;
          top: -25px;
          right: 0;
          height: 100%;
          width: 100%;
          background-image: url('../../assets/img/community2.png');
          background-position: top;
          background-repeat: no-repeat;
          background-size: contain;
        }
      }

      .communityInfo {
        width: 50%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0 48px;
      }
    }

    button {
      margin-top: 30px;
    }
  }

  .footer {
    position: relative;
    background-image: url('../../assets/img/pptrials_footer_cropped.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    color: $lpLightColor;

    .innerFooter {
      padding: 0 20px;
      display: block;

      h4 {
        color: $lpLightColor;
      }

      .top {
        height: 100px;
        display: flex;
        justify-content: space-around;
        align-items: center;
        border-bottom: 1px solid $lpLightColor;

        h4 {
          margin: 0;
        }
      }

      .middle {
        width: 100%;
        height: 170px;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .bottom {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 50px;
        border-top: 1px solid $lpLightColor;

        a {
          color: $lpHeaderColor;
          text-decoration: none;
        }
      }
    }

    .footerLogo {
      .logotype {
        width: 260px;
        height: 67px;
        margin-top: 5px;
        background-image: url('../../assets/img/logo-horiz.png');
        background-repeat: no-repeat;
        background-size: contain;
      }

      .links {
        display: flex;
        flex-direction: column;

        button {
          padding: 0;
          font-weight: normal;
          font-size: 18px;
          line-height: 26px;
          color: #ffffff;
          border: none;
          background: none;
          outline: none;

          &:hover {
            text-shadow: 1px 1px 1px $lpShadowColor;
          }
        }
      }
    }

    .googlePay {
      width: 210px;
      height: 70px;
      background-image: url('../../assets/img/google-pay.png');
      background-size: contain;
      background-repeat: no-repeat;

      a {
        display: inline-block;
        width: 100%;
        height: 100%;
      }
    }

    .footerInfo {
      padding-left: 40px;

      p {
        margin: 5px 0;
      }
    }

    .mobileFooter {
      display: none;
      padding: 10px;
    }

    .mobileTop {
      display: flex;

      .mobileLeft {
        flex: 1;

        .logotype {
          width: 90%;
        }

        .googlePay {
          width: 90%;
        }

        .links,
        .googlePay {
          margin: 15px 0 15px 15px;
        }
      }

      .mobileRight {
        flex: 1;

        .buttonWrapper {
          width: 100%;
          display: flex;
          justify-content: center;
          margin-top: 15px;
        }

        .footerInfo {
          margin-top: 35px;
          padding-left: 10px;
        }
      }
    }

    .mobileBottom {
      font-size: 12px;
    }
  }
}

@media only screen and (max-width: $width-lg) {
  .landingPage {
    .sectionPadding {
      padding: 20px 100px;
    }

    .ultimate {
      .ultimateWrapper {
        .content {
          .ultimateHeader {
          }
        }
      }
    }

    .benefits {
      .benefitsInfo {
        padding: 0;
      }

      .grid {
        padding: 0;
      }
    }

    .community {
      margin-top: 40px;

      .communityWrapper {
        .communityInfo {
          padding: 0;
        }
      }
    }
  }
}

@media only screen and (max-width: $width-md) {
  .landingPage {
    .onlyDesktop {
      display: none;
    }

    .sectionPadding {
      padding: 20px;
    }

    .ultimate {
      width: 100vw;

      .ultimateWrapper {
        background-image: url('../../assets/img/yellowBlue_bg_med.png');

        .content {
          top: 20px;
          left: 20px;

          .ultimateHeader {
            max-width: 47vw;
            font-size: 27px;
          }

          .ultimateText {
            padding-left: 0;
            max-width: 40vw;
            font-size: 18px;
          }

          .buttonsGroup {
            margin-left: 2vw;

            .greenButton {
              margin: 10px 0;
            }
          }
        }
      }
    }

    .benefits {
      width: 100vw;

      .benefitsInfo {
        padding: 0;
      }

      .grid {
        padding: 0;
      }
    }

    .rings {
      width: 100vw;

      .cardWrapper {
        flex-direction: column;

        .card {
          width: 100%;
          flex-direction: initial;
          align-items: center;
          justify-content: space-around;

          .square {
            width: 150px;
            height: 150px;
          }

          .plainText {
            margin-bottom: 65px;
            width: 50%;
          }
        }
      }
    }

    .mobile {
      width: 100vw;

      .mobileWrapper {
        align-items: center;
      }

      .mobileList {
        display: none;
      }

      .mobileListSmall {
        display: block;
      }
    }

    .visualize {
      width: 100vw;

      .visualizeWrapper {
        flex-direction: column;

        .visualizeInfo {
          width: 100%;
        }

        .visualizeLogo {
          width: 100%;
        }
      }
    }

    .community {
      width: 100vw;

      .communityWrapper {
        .communityInfo {
        }
      }
    }

    .footer {
      width: 100vw;
    }
  }
}

@media only screen and (max-width: $width-xs) {
  .landingPage {
    .freeTrial {
      padding: 8px 30px;
    }

    .ultimate {
      .ultimateMobileHeader {
        display: block;
      }

      .ultimateWrapper {
        background-image: url('../../assets/img/yellowBlue_bg_small.png');
        aspect-ratio: 1;

        .content {
          display: none;

          .ultimateHeader {
          }
        }
      }

      .mobileContent {
        display: block !important;
      }
    }

    .benefits {
      .benefitsInfo {
      }

      .grid {
        .gridTr {
          .gridTd {
            padding: 5px 5px 5px 27px;
          }
        }
      }
    }

    .rings {
      .cardWrapper {
        .card {
          .square {
            width: 100px;
            height: 100px;
          }

          .plainText {
            width: 60%;
          }
        }
      }
    }

    .community {
      .communityWrapper {
        .communityInfo {
          .greenButton {
            display: none;
          }
        }
      }
    }

    .footer {
      .innerFooter {
        display: none;
      }

      .mobileFooter {
        display: block;
      }
    }
  }
}
