.modal {
  flex-direction: column;
  display: flex;
  box-shadow: 0 11px 15px -7px rgba(0, 0, 0, 0.2),
    0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  background-color: #ffffff;
  border: 1px solid #ced4da;
  border-radius: 5px;
  width: 400px;
  position: absolute;
  max-width: 90%;
  left: calc(50% - 200px);
  top: calc(50% - 100px);
  padding: 10px;
  z-index: 100;
  .modalHeader {
    display: flex;
    justify-content: space-between;
    .title {
      font-size: 20px;
    }
  }
  .modalContent {
    border: 1px solid #ced4da;
    border-radius: 5px;
    padding: 20px;
    justify-content: center;
    display: flex;
    flex-direction: column;
    margin-top: 10px;
  }
  input {
    margin-top: 20px;
  }
  .submitButton {
    margin-top: 20px;
  }
}
