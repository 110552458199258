@import "../../../../assets/scss/variables";

.batchFormDialog {
  width: $formDialogWidth;
  max-width: $formDialogMaxWidth;

  .checkbox {
    padding-left: 14px;
    margin-bottom: 0;
  }

  .button {
    margin: 1rem 0.5rem;

    &:last-child {
      margin-right: 0;
    }
  }

  .noTopPadding {
    padding-top: 0;
  }

  .noRightPadding {
    padding-right: 0;
  }
}
