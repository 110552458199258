@import "../../../assets/scss/variables";

.trialList {
  .circleCentered {
    text-align: center;
  }

  .statusIcon {
    font-size: 0.75em;
  }

  .partnerIcon {
    display: flex;
    justify-content: center;
  }

  .linkStyle {
    cursor: pointer;
    color: $light-blue;
    text-decoration: underline;
  }

  .linkStyle:hover {
    text-decoration: none;
  }

  .tableHeader {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .tableLabel {
      display: flex;
      flex-direction: row;
      margin: 0;

      .tableName {
        margin: 4px 15px 0 0;
        font-weight: 500;
      }
    }
  }
  .customTableStyle {
    display: flex;
    flex-direction: column;
  }
}

@media only screen and (max-width: $width-xl) {
  .trialList {
    width: 81%
  }
}

@media only screen and (max-width: $width-md) {
  .trialList {
    .customTableStyle > div:nth-child(2),
    .customTableStyle > div:nth-child(3) {
      height: calc(100% - 35px) !important;
    }
  }
}

@media only screen and (max-width: $width-sm) {
  .trialList {
    .tableHeader {
      .tableLabel {
        margin-bottom: 5px;
      }
    }
  }
}