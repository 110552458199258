@import '../../../../assets/scss/variables';

.logImages {
  display: flex;
  align-items: center;

  .logImage {
    margin: 0 5px;
    border-radius: 3px;
    width: 48px;
    height: 48px;
    object-fit: contain;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2), 0 3px 6px rgba(0, 0, 0, 0.25);
  }

  .pencilButton {
    margin-left: 5px;
    border-radius: 50%;
    height: 2.357rem;
    width: 2.357rem;
    color: #ffffff;
    background: #2196f3 !important;
    border: 1px solid #2196f3 !important;
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2), 0 3px 4px rgba(0, 0, 0, 0.14);
  }
}

.updateImage {
  display: flex;

  div {
    margin-right: 5px;
  }
}

.activeUpdateImage {
  cursor: initial !important;
  box-shadow: none !important;
  background: transparent !important;

  &:active {
    cursor: none;
    box-shadow: none;
    background: transparent;
  }
}

@media only screen and (max-width: $width-md) {
  .logImageDialog {
    width: 400px;
  }
}
