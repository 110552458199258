.headerTemplate {
  background-color: transparent;
  display: flex;
  align-items: center;
}

.progressBar {
  width: 350px !important;
  height: 7px !important;
}

.template {
  width: 75%;

  .employeeImage {
    margin: 0 5px;
    border-radius: 5px;
    object-fit: contain;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2), 0 3px 6px rgba(0, 0, 0, 0.25);
  }
}
