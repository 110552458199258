@import "../../../assets/scss/variables";


.locationList {

  .linkStyle {
    cursor: pointer;
    color: $light-blue;
    text-decoration: underline;
  }

  .linkStyle:hover {
    text-decoration: none;
  }

  .tableHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .tableLabel {
      display: flex;
      flex-direction: row;
      margin: 0;

      .tableName {
        margin: 4px 15px 0 0;
        font-weight: 500;
      }
    }
  }

  .footerMessage {
    color: $text-grey;
  }
}

@media only screen and (max-width: $width-xl) {
  .locationList {
    width: 81%
  }
}