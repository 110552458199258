@import "../../../assets/scss/variables";

.navigationItem {
  color: $dark-blue;
  padding-bottom: 6px;

  a, a:visited {
    text-decoration: none;
    display: block;
    padding: 1em 0.3em;
    font-size: 1.25rem;
    font-weight: 600;
    color: inherit;
  }
;

  a:active, a.active {
    color: $light-blue
  }
;

  @media only screen and (min-device-width: $width-sm) and (max-device-width: $width-lg) {
    a, a:visited {
      font-size: 1rem;
    }
  }
}

.navigationItem:hover {
  color: $light-blue;
}

.hidden {
  visibility: hidden;
}

;

.noClickable {
  pointer-events: none;
  opacity: 0.7;
}

.clickable {
  pointer-events: all;
}

.clickableLink {
  cursor: pointer;
}