.errorDialog {
  border: 3px solid red !important;
}

.buttonStyle {
  padding: 0 !important;
  border: none !important;
  color: rgb(0, 0, 238) !important;
  outline: none !important;
  background: transparent !important;
  box-shadow: none !important;
  text-decoration: underline;
}