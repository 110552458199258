@import "../../../../../assets/scss/variables";

.editorMenu {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 20px;

  .menuItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;

    .errorMessage {
      position: absolute;
      bottom: -15px;
      right: 0;
    }

    .menuInput {
      width: 65px;
    }

    .menuLabel {
      margin: 0 10px;
    }
  }
}

@media only screen and (max-width: $width-lg) {
  .editorMenu {
    justify-content: space-around;
    .menuItem {
      .menuInput {
        width: 60px;
      }
    }
  }
}

@media only screen and (max-width: $width-md) {
  .editorMenu {
    justify-content: space-around;
    .menuItem {
      flex-direction: column;
    }
  }
}