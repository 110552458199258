@import "../../../../assets/scss/variables.scss";

.systemForm {
  height: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;

  .hideElement {
    display: none;
  }

  .buttonsGroup {
    display: flex;
    justify-content: flex-end;
    padding-right: 0;
  }
}

.inner {
  flex: 1;
  background: #ffffff;
  padding: 0 1rem 1rem 1rem;
  border: 1px solid lightgrey;
  margin: 0 1rem 1rem;
  border-radius: 4px;
  overflow-y: auto;

  ul {
    padding-left: 0;
  }
}

.button {
  margin: 1rem 0.5rem !important;

  &:last-child {
    margin-right: 0 !important;
  }
}

@media only screen and (max-width: $width-xxl) {
  .systemForm {
    width: $addDialog-width-xxl;

  }
}

@media only screen and (max-width: $width-xl) {
  .systemForm {
    width: $addDialog-width-xl;
  }
}

@media only screen and (max-width: $width-md) {
  .systemForm {
    .buttonsGroup {
      justify-content: flex-start;
      padding-left: 7px;
    }
  }
}
