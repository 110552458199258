@import '../../../../assets/scss/variables';

.plantSelectionList {
  .tableHeader {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .tableLabel {
      display: flex;
      flex-direction: row;
      margin: 0;

      button {
        margin-right: 8px;
      }
    }
  }

  .linkStyle {
    cursor: pointer;
    color: $light-blue;
    text-decoration: underline;
    border-style: none;
    background-color: rgba(117, 190, 218, 0);
  }

  .footerMessage {
    color: $text-grey;
  }

  .customTableStyle {
    display: flex;
    flex-direction: column;
  }

  .customTableStyle > div:nth-child(2) > div > div:nth-child(2),
  .customTableStyle > div:nth-child(3) > div > div:nth-child(2) {
    height: calc(100% - 55px) !important;
  }
}

@media only screen and (max-width: $width-xl) {
  .plantSelectionList {
    width: 81%;
  }
}

@media only screen and (max-width: $width-sm) {
  .plantSelectionList {
    .tableHeader {
      .tableLabel {
        margin-bottom: 5px;
      }
    }

    .customTableStyle {
      height: calc(100% - 125px) !important;
    }
  }
}
