@import "../../../assets/scss/variables";


.employeeList {

  .linkStyle {
    cursor: pointer;
    color: $light-blue;
    text-decoration: underline;
    border-style: none;
    background-color: rgba(117, 190, 218, 0.0);
  }

  .linkStyle:hover {
    text-decoration: none;
  }

  .tableHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .tableLabel {
      display: flex;
      flex-direction: row;
      margin: 0;

      .tableName {
        margin: 4px 15px 0 0;
        font-weight: 500;
      }
    }
  }

  .footerMessage {
    color: $text-grey;
  }

  .employeeThumb {
    margin: 0 5px;
    border-radius: 21px;
    height: 45px;
    object-fit: contain;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2), 0 3px 6px rgba(0, 0, 0, 0.25);
  }
}

@media only screen and (max-width: $width-xl) {
  .employeeList {
    width: 81%
  }
}
