.dynamicField {
  .inputZone {
    .calendarButton {
      width: 215px;
      height: 33px;
      background: white;
      border: 1px solid #ced4da;
      border-radius: 3px;
      text-align: start;
      color: #495057;
      justify-content: flex-end;
      font-size: inherit;

      span:first-child {
        margin-right: 14px;
      }
      i {
        margin-right: 2px;
      }
    }

    .disabledStyle {
      opacity: 0.6;
    }

    .calendarButtonError {
      width: 215px;
      height: 33px;
      background: white;
      border: 1px solid red;
      border-radius: 3px;
      text-align: start;
      color: #495057;
      padding: 5px 14px 5px 5px;
      justify-content: flex-end;
      font-size: inherit;

      span:first-child {
        margin-right: 12px;
      }
      i {
        margin-right: 2px;
      }
    }
  }
  .template {
    width: 60%;

    .templateImage {
      margin: 0 5px;
      border-radius: 5px;
      object-fit: contain;
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2), 0 3px 6px rgba(0, 0, 0, 0.25);
    }
  }
  .updateImage {
    display: flex;

    div {
      margin-right: 5px;
    }
    span {
      aspect-ratio: auto !important;
    }
  }

  .activeUpdateImage {
    cursor: initial !important;
    box-shadow: none !important;
    background: transparent !important;

    &:active {
      cursor: none;
      box-shadow: none;
      background: transparent;
    }
  }

  .imageHeader {
    background-color: transparent;
    position: relative;
    .progressBar {
      position: absolute;
      right: 35px;
      top: 50%;
      .progress {
        width: 250px;
        height: 7px;
        position: relative;
      }
    }
  }

  .emptyTemplate {
    i {
      font-size: 3em;
      border-radius: 50%;
      background-color: var(--surface-b);
      color: var(--surface-d);
    }
    span {
      font-size: 1em;
      color: var(--text-color-secondary);
    }
  }
}
