.backDrop {
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.8);

  i {
    position: absolute;
    top: 20px;
    right: 20px;
    color: #FFFFFF;
    font-size: 3rem;

    &:hover {
      cursor: pointer;
    }
  }
}