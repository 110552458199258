@import '../../../../assets/scss/variables.scss';
.plantGoalResultsList {
  .customTableStyle {
    display: flex;
    flex-direction: column;
  }

  .tableHeader {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;

    .buttonGroup {
      display: flex;
      flex-direction: row;
      margin: 0;

      .tableName {
        margin: 4px 15px 0 0;
        font-weight: 500;
      }
      button:last-child {
        margin-left: 10px;
      }
    }
  }

  .linkStyle {
    cursor: pointer;
    color: $light-blue;
    text-decoration: underline;
    border-style: none;
    background-color: rgba(117, 190, 218, 0);
  }

  .linkStyle:hover {
    text-decoration: none;
  }
  .dialog {
    width: $deleteDialog-width;
  }
}
