@import '../../../../assets/scss/variables';

.cropModal {
  width: $formDialogWidth;
  max-width: $formDialogMaxWidth;

  .readOnly {
    &:hover {
      border-color: #ced4da !important;
    }

    &:active {
      box-shadow: none;
    }

    &:focus {
      box-shadow: none !important;
      border-color: #ced4da !important;
    }
  }

  .datepicker {
    display: flex;
    width: 165px;
    height: 33px;
    background: #fff;
    border: 1px solid #ced4da;
    border-radius: 3px;
    text-align: start;
    color: #495057 !important;
    margin: 0 25px 0 14px;
    justify-content: flex-end;
    align-items: center;
    input {
      border: none;
    }
  }
  .weekLabel {
    width: 75px;
    margin-top: 7px;
  }
  .week {
    width: 70px;
  }
}
@media only screen and (max-width: $width-md) {
  .cropModal {
    .datepicker {
      margin: 0;
    }
    .weekLabel {
      display: none !important;
    }
  }
}
