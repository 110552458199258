@import "../../../assets/scss/variables.scss";

.applicationFormDialog {
  width: 450px;
  max-width: 90%;

  .describer {
    padding-top: 8px !important;
    padding-left: 0 !important;
  }

  .button {
    margin: 1rem 0.5rem;

    &:last-child {
      margin-right: 0;
    }
  }
}