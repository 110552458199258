@import "../../../../assets/scss/variables";

.fieldList {

  .linkStyle {
    cursor: pointer;
    color: $light-blue;
    text-decoration: underline;
  }

  .linkStyle:hover {
    text-decoration: none;
  }

  .tableHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;

    .tableLabel {
      display: flex;
      flex-direction: row;
      margin: 0;

      button {
        margin-right: 8px;
      }
    }
  }

  .footerMessage {
    color: $text-grey;
  }
}

@media only screen and (max-width: $width-xl) {
  .fieldList {
    width: 81%
  }
}

@media only screen and (max-width: $width-sm) {
  .fieldList {
    .customTableStyle {
      height: calc(100% - 130px) !important;
    }

    .customTableStyle > div:nth-child(2),
    .customTableStyle > div:nth-child(3) {
      height: calc(100% - 50px) !important;
    }
  }
  .tabSearch, .tableLabel {
    margin-top: 5px!important;
  }
}