@import "../../../../../assets/scss/variables";

.labelField {
  display: inline-block;
  position: relative;
  color: #000;
  cursor: pointer;
  width: fit-content;
  height: fit-content;

  .highlightBg {
    background-color: $light-grey;
  }

  .label {
    margin-right: 5px;
  }
}

.textField {
  z-index: 10;
}

.resizableItem {
  width: 100px;
  height: 85px;
}