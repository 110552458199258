@import '../../../../assets/scss/variables';

.logForm {
  width: 650px;
  max-width: $formDialogMaxWidth;

  .button {
    margin: 1rem 0.5rem;

    &:last-child {
      margin-right: 0;
    }
  }
  .uploader {
    span {
      width: 34px;
    }
  }
}
