@import "../../../../assets/scss/variables.scss";

.productInfo {
  height: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;

  .productForm {
    flex: 1;
    background: #ffffff;
    padding: 1.25rem;
    border: 1px solid lightgrey;
    margin: 0 1rem 1rem;
    border-radius: 4px;
    overflow-y: auto;

    .button {
      margin: 1rem 0.5rem;

      &:last-child {
        margin-right: 0;
      }
    }

    h3 {
      margin-top: 0;
    }
  }

  .spinner {
    position: absolute;
    top: calc(50% - 19px);
    left: calc(50% - 19px);
    color: black;
    font-size: 30px
  }

}

@media only screen and (max-width: $width-xxl) {
  .productInfo {
    width: $addDialog-width-xxl;

  }
}

@media only screen and (max-width: $width-xl) {
  .productInfo {
    width: $addDialog-width-xl;
  }
}