@import "../../../../assets/scss/variables";


.trialFieldList {

  .linkStyle {
    cursor: pointer;
    color: $light-blue;
    text-decoration: underline;
  }

  .linkStyle:hover {
    text-decoration: none;
  }

  .tableHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .tableLabel {
      display: flex;
      flex-direction: row;
      margin: 0;

      .tableName {
        margin: 4px 15px 0 0;
        font-weight: 500;
      }
    }
  }

  .checkboxEditor {
    width: 66px;
    border: 1px solid #ced4da;
    height: 33px;
    padding: 5px;
    border-radius: 3px;
  }

  .footerMessage {
    color: $text-grey;
  }

}

@media only screen and (max-width: $width-xl) {
  .trialFieldList {
    width: 81%
  }
}
