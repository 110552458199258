@import '../../../assets/scss/variables';

.locationFormDialog {
  width: $formDialogWidth;
  max-width: $formDialogMaxWidth;

  .button {
    margin: 1rem 0.5rem;

    &:last-child {
      margin-right: 0;
    }
  }
}

@media only screen and (max-width: $width-md) {
  .locationFormDialog {
    width: 90% !important;
    margin: 0 auto;
  }
}


