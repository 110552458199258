@import "../../assets/scss/variables";

.signUpForm {
  width: 100%;
  overflow: auto;

  form {
    width: 90%;
    max-width: 580px;
    margin: 20px auto;
    border: 1px solid white;
    border-radius: 5px;
    padding: 9px 25px;

    img {
      width: 10%;
      display: block;
      margin: 30px auto;
    }
  }

  h1, h2, h3, p {
    text-align: center;
  }

  h1 {
    margin: 15px 0 0;
    font-size: 25px;
  }

  .checkbox {
    margin: 0;

    a:visited {
      color: #2196F3
    }
  }
}

@media only screen and (max-width: $width-md) {
  .signUpForm {
    width: 100vw;
  }
}

@media only screen and (max-width: $width-sm) {
  .signUpForm {
    .continue {
      margin-top: 15px;
    }
  }
}