@import "../../../assets/scss/variables";

.cropSelectionDialog {
  width: 90%;

  .label {
    vertical-align: middle;
  }

  .button {
    margin: 1rem 0.5rem;

    &:last-child {
      margin-right: 0;
    }
  }

  .hidden {
    visibility: hidden;
  }

  .treeTable {
    height: 400px;
    max-height: 90%;
    overflow: auto;
  }
}
