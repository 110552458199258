@import "../../../assets/scss/variables.scss";

.taskList {

  .circleCentered {
    text-align: center;
  }

  .statusIcon {
    font-size: 0.75em;
  }

  .linkStyle {
    cursor: pointer;
    color: $light-blue;
    text-decoration: underline;
    border-style: none;
    background-color: rgba(117, 190, 218, 0.0);
  }

  .linkStyle:hover {
    text-decoration: none;
  }

  .tableHeader {
    display: flex;
    justify-content: space-between;

    .tableLabel {
      display: flex;
      flex-direction: row;
      margin: 0;

      button {
        margin-right: 8px;
      }

      .tableName {
        margin: 4px 15px 0 0;
        font-weight: 500;
      }
    }
  }

  .completed {
    font-size: 1.1em
  }
}

@media only screen and (max-width: $width-xl) {
  .taskList {
    width: 81%
  }
}

@media only screen and (max-width: $width-sm) {
  .taskList {
    .tableHeader {
      flex-direction: column;

      .tableLabel {
        margin-bottom: 10px;
      }
    }

    .customTableStyle > div:nth-child(2),
    .customTableStyle > div:nth-child(3) {
      height: calc(100% - 40px) !important;
    }
  }
}