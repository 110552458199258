$fontSize:14px !default;
$fontFamily:"Nunito",-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Barcode-Font","Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji" !default;
$transitionDuration:.2s !default;
$animationDuration:.4s !default;
$animationTimingFunction:cubic-bezier(.05,.74,.2,.99) !default;
$letterSpacing:normal !default;
$borderRadius:4px !default;
$tabletBreakpoint:991px !default;
$phoneBreakpoint:576px !default;


$dark-blue: #143b6d;
$base-blue: #006e97;
$dark-cyan: #00838F;
$title-bar-cyan: #0097A7;
$light-blue: var(--blue-400);
$lighter-blue: #cee5f2;
$base-green: #58857d;
$base-purple: #a090bf;
$base-navy-blue: #39637f;
$base-brown: #ad8265;
$base-grey: #4c4c4c;
$text-grey: var(--text-color);
$light-grey: #dee2e6;
$lighter-grey: #f8f9fa;

$primaryColor: #00BCD4;
$primaryLightColor: scale-color($primaryColor, $lightness: 60%) !default;
$primaryDarkColor: scale-color($primaryColor, $lightness: -10%) !default;
$primaryDarkerColor: scale-color($primaryColor, $lightness: -20%) !default;
$primaryTextColor: #ffffff;

$highlightBg: #E0F7FA;
$highlightTextColor: #495057;

$lpParagraphColor: #606060;
$lpHeaderColor: #021F54;
$lpButtonColor: #41772a;
$lpShadowColor: #3B4148FF;
$lpGreyColor: #606060FF;
$lpLightColor: #FFFFFF;
$lpGoldenColor: #FBE211FF;
$lpBackgroundColor: #61A5F1FF;
$lpSetupColor: #DE4383;
$lpCropsColor: #774898;
$lpMobileColor:  #F3AE4B;
$shareColor: #80BA50;
$visualColor: #00A8B5;
$supportHeaderColor: #006e8c;
$supportColor: #232323;
$dangerColor: #f44336;

$width-xs: 576px;
$width-sm: 768px;
$width-md: 993px;
$width-lg: 1024px;
$width-xl: 1366px;
$width-xxl: 1800px;
$addDialog-width-xxl: 40vw;
$addDialog-width-xl: 43vw;
$addDialog-width-lg: 58vw;
$addDialog-width-md: 77vw;
$refTable-width: 35vw;
$refTable-width-xl: 32vw;
$refTable-width-lg: 45vw;
$deleteDialog-width: 450px;
$formDialogWidth: 600px;
$formDialogMaxWidth: 90%;
