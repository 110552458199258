@import "../../../../assets/scss/variables.scss";

.fieldFormDialog {
  width: $formDialogWidth;
  max-width: $formDialogMaxWidth;

  .button {
    margin: 1rem 0.5rem;

    &:last-child {
      margin-right: 0;
    }
  }
}

.checkbox {
  margin: 10px 0 5px;
}

.refTable {
  width: $refTable-width;
}

.hideElement {
  visibility: hidden;
}