.outdated {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: #2b2b33;
  .card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 300px;
    height: 400px;
    border-radius: 5px;
    background-color: #fff;
    .envelope {
      width: 60px;
      height: 60px;
      background-image: url('../../../assets/img/envelope.png');
      background-size: contain;
      background-repeat: no-repeat;
    }
  }
  .hint {
    margin-top: 10px;
    color: white;
  }
}
