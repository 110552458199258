.plantGoalsBatchFormDialog {
  width: 410px;
  button:last-child {
    margin-left: 10px;
  }
  .fieldInput {
    border-color: #ced4da;
    box-shadow: none;
    opacity: 0.6;
    &:hover,
    &:active {
      border-color: #ced4da;
      box-shadow: none;
      opacity: 0.6;
    }
  }
}
