@import "../../../../../assets/scss/variables.scss";

.fileUploader {
  margin-bottom: 15px;

  .plantImage {
    margin: 0 5px;
    border-radius: 5px;
    object-fit: contain;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2), 0 3px 6px rgba(0, 0, 0, 0.25);
  }

  .progressBar {
    width: 350px;
    height: 7px;
  }

  div.uploaderHeader {
    background-color: transparent;
    display: flex;
    align-items: center;
  }

  .preview {
    width: 75%;
  }

  .emptyAreaIcon {
    font-size: 3em;
    border-radius: 50%;
    background-color: var(--surface-b);
    color: var(--surface-d);
  }

  .emptyAreaText {
    font-size: 1em;
    color: var(--text-color-secondary)
  }
}