@import "../../../../assets/scss/variables";


.sectionsList {

  .linkStyle {
    cursor: pointer;
    color: $light-blue;
    text-decoration: underline;
    border-style: none;
    background-color: rgba(117, 190, 218, 0.0);
  }

  .linkStyle:hover {
    text-decoration: none;
  }

  .tableHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .tableName {
      margin: 4px 15px 0 0;
      font-weight: 500;
    }

  }

  .footerMessage {
    color: $text-grey;
  }
}

@media only screen and (max-width: $width-xl) {
  .sectionsList {
    width: 81%
  }
}

@media only screen and (max-width: $width-sm) {
  .sectionsList {
    .tableHeader {
      button {
        padding: 0.5rem;
      }
    }
  }
}