.image {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  min-height: 140px;
  margin-bottom: 15px;
  img {
    width: 50%;
    height: auto;
    border-radius: 5px;
    box-shadow: 0 3px 6px #0003, 0 3px 6px #00000040;
    object-fit: contain;
  }
  .buttons {
    display: flex;
    .dangerButton {
      color: #d32f2f;
      margin-left: 5px;
      padding: 17px;
      &:hover {
        color: #d32f2f !important;
        box-shadow: 0 0 0 0.2rem #edacac;
        background-color: white;
      }
      &:focus {
        color: #d32f2f !important;
        box-shadow: 0 0 0 0.2rem #edacac;
        background-color: white;
      }
    }
    .hidden {
      opacity: 0;
      height: 0;
      width: 0;
      line-height: 0;
      overflow: hidden;
      padding: 0;
      margin: 0;
    }
  }
}
