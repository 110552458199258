@import '../../../../assets/scss/variables';

.trialPlantList {
  .tableHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .tableLabel {
      display: flex;
      flex-direction: row;
      margin: 0;

      .tableName {
        margin: 4px 15px 0 0;
        font-weight: 500;
      }
    }
  }

  .linkStyle {
    cursor: pointer;
    color: $light-blue;
    text-decoration: underline;
    border-style: none;
    background-color: rgba(117, 190, 218, 0);
  }

  .footerMessage {
    color: $text-grey;
  }

  .p-datatable.p-datatable-sm .p-datatable-tbody > tr > td,
  .p-datatable.p-datatable-sm .p-datatable-thead > tr > th {
    padding: 0 0.5rem !important;
  }
}

@media only screen and (max-width: $width-xl) {
  .trialPlantList {
    width: 81%;
  }
}
