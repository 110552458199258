.panelHeader {
  display: flex;
  align-items: flex-end;
  padding: 15px 0;

  .panelPreview {
    position: relative;
    width: 300px;
    height: 200px;
    border: 1px solid grey;

    i {
      font-size: 50px;
      position: absolute;
      top: calc(50% - 25px);
      left: calc(50% - 25px);
      color: grey;
    }
  }

  .panelMenu {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    background-color: #e9ecef;

    input {
      width: 60px
    }

    button {
      span.pi-trash {
        font-size: 1.5rem;
      }
    }
  }

  .menuMargin {
    margin: 0 5px;
  }

  .hideElement {
    display: none !important;
  }

  .fontBold {
    font-weight: bolder;
  }

  .fontNormal {
    font-weight: normal;
  }

  .toggleButton {
    background-color: transparent;
    color: #607D8B;
    border: 1px solid;
    &:hover{
      background: rgba(96, 125, 139, 0.04)!important;
      color: #607D8B!important;
      border: 1px solid!important;
    };
  }
}