@import '../../assets/scss/variables';

.navigation {
  position: relative;

  .onlyDesktop {
    display: block;
  }

  .supportMenu {
    position: absolute;
    right: 40px;
    top: 65px;
    width: 200px;
    height: 100px;
    border: 2px solid #000000;
    background-color: #fff;
    z-index: 100;
  }

  .navigationItems {
    background: #ffffff;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    list-style: none;
    height: 100%;
    margin: 0;
    padding-inline-start: 15px;

    .navbarBrandWidth {
      width: 21%;
    }

    .homeNavbarBrandWidth {
      width: 30%;
    }

    .navbarBrandImage {
      margin: 3px 0 3px 3px;
      background-image: url('../../assets/img/logo.png');
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      text-decoration: none;
      display: flex;
      align-items: center;

      li {
        width: 100%;
        height: 100%;

        a {
          display: inline-block;
          width: 100%;
          height: 100%;
          padding: 0;
        }
      }
    }

    .centerItems {
      display: flex;
      flex: 1;
      flex-flow: row wrap;
      align-items: center;
      justify-content: space-around;

      li:last-child,
      li:nth-last-child(2) {
        padding-bottom: 0 !important;
      }
    }

    .burgerMenu {
      display: none;
      position: absolute;
      left: calc(24% + 15px);
      top: calc(50% - 15px);

      .burger {
        font-size: 1.5rem;
        color: $dark-blue;
        margin-top: 3px;
      }
    }

    .rightSideItems {
      display: flex;
      flex-flow: row wrap;
      justify-content: flex-end;
      align-items: center;
      padding-right: 20px;

      .demo {
        background-color: $lpButtonColor;
        border: 1px solid $lpButtonColor;
        border-radius: 3px;
        outline: none;
        margin-right: 5px;

        &:hover {
          background-color: $lpButtonColor;
          border: 1px solid $lpButtonColor;
          color: $lpLightColor;
          opacity: 0.8;
        }
      }

      .profileItem {
        padding: 0 10px 5px 10px;
        border-left: 1px solid $light-grey;
        border-right: 1px solid $light-grey;

        button {
          display: flex;
          align-items: center;
        }

        .profileImage {
          width: 2.5rem;
          height: 2.5rem;
          margin-right: 0.5rem;
          border-radius: 17px;
        }

        .profileName {
          color: $dark-blue;
        }
      }

      .auth {
        font-size: 1.25rem;
        background: white;
        color: $dark-blue;
        border: none;
        margin-bottom: 3px;
      }

      .bell:focus,
      .bell:hover,
      .auth:focus,
      .auth:hover {
        outline: none;
        background: white;
        box-shadow: none;
        color: $light-blue;
      }
    }
  }

  .cog {
    font-size: 1.5rem;
    color: $dark-blue;
    cursor: pointer;
  }

  .question {
    overflow: visible;
    display: flex;
    margin-bottom: 2px;
    padding: 1rem 0;
    align-items: center;

    i {
      font-size: 1.5rem;
      color: $dark-blue;
      cursor: pointer;
    }
  }

  .bell {
    padding-top: 2px;
    font-size: 1.5rem;
    color: $dark-blue;
  }

  .mobileOnly {
    display: none !important;
  }

  .noPadding {
    padding: 0 !important;
  }
}

@media only screen and (max-width: $width-md) {
  .navigation {
    .navigationItems {
      .navbarBrandImage {
        width: 26%;
      }

      .burgerMenu {
        display: block;
        left: calc(27% + 15px);
      }

      .desktopOnly {
        display: none;
      }

      .mobileOnly {
        display: flex !important;
      }

      .centerItems {
        display: none;
      }
    }
  }
}

@media only screen and (max-width: $width-xs) {
  .navigation {
    .onlyDesktop {
      display: none !important;
    }

    .navigationItems {
      .navbarBrandImage {
        width: 31%;
      }

      .burgerMenu {
        display: block;
        left: calc(32% + 15px);
      }

      .centerItems {
        display: none;
      }

      .rightSideItems {
        .profileItem {
          .profileName {
            display: none;
          }
        }
      }
    }
  }
}
