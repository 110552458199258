@import "../../../assets/scss/variables";

.labelTemplates {

  .hideElement {
    visibility: hidden;
  }

  .caption {
    margin-bottom: 20px;
    color: $highlightTextColor;
    border-bottom: 1px solid $highlightTextColor;

    h2 {
      font-weight: normal;
    }
  }

  .linkStyle {
    cursor: pointer;
    color: $light-blue;
    text-decoration: underline;
    border-style: none;
    background-color: rgba(117, 190, 218, 0.0);
  }
}

.systemTemplate {
  pointer-events: none;
  opacity: 0.6;
}

@media only screen and (max-width: $width-xl) {
  .LabelTemplates {
    width: 81%
  }
}