@import "../../../../assets/scss/variables.scss";

.printCropDialog {
  width: $addDialog-width-lg;
  height: 90vh;
  overflow: auto;

  .spinner {
    color: grey
  }

  .show {
    display: block;
  }

  .hide {
    display: none;
  }

  .noTemplate {
    height: calc(100% - 130px);
    background: white;
    width: 500px;
    padding: 20px 30px;
    margin: 30px auto;

    .noTemplateMessage {
      display: inline-block;
      padding: 30px 15px;
      border: 3px solid red !important;
      text-decoration: none;
      font-size: 16px;
      font-weight: bold;
      color: $lpParagraphColor
    }
  }

  .contentWrapper {
    width: fit-content;
    height: calc(100% - 130px);
    margin: 0 auto;
  }

  .imageBorder {
    border: 1px solid grey;
  }

  .preview {
    position: relative;
    width: fit-content;
    height: fit-content;
    margin: 15px auto;
  }

  .buttonsBlock {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 15px;

    button {
      margin-left: 15px;
    }
  }

  .wrapper {
    position: relative;
  }
}

@media only screen and (max-width: $width-lg) {
  .printCropDialog {
    .contentWrapper {
      width: 100%;

      .preview {
        width: 100%;
        height: auto;

        img {
          width: 100%;
          height: auto;
        }
      }
    }
  }
}