.subscriptionCard {
  width: 360px;
  height: 95%;

  div:nth-child(2) > div {
    padding-bottom: 0;
  }

  .cardHeader {
    text-align: center;

    button {
      width: 200px;
      font-size: larger;
      margin-top: 20px;

      span {
        font-weight: 600;
      }
    }
  }

  ul {
    padding-left: 30px;

    li {
      padding-left: 1em;
      margin-bottom: 1em;
      font-weight: 600;

      &::marker {
        content: '\2713';
        color: green;
        font-size: large;
      }
    }
  }

  .subTitle {
    text-align: center;
    font-size: large;
    font-weight: 600;
  }
}