.multiSelectFilter {
  .labelText {
    color: white;
    margin: 15px 0 5px;
    white-space: nowrap;
  }

  div.multiselectInput {
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 0 auto;
    height: 37px;
    border-color: transparent !important;
    box-shadow: none !important;
  }
}

.selectAll > div:first-child > div:first-child {
  display: none
}
