.locationInfo {
  height: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;

  .locationForm {
    flex: 1;
    background: #ffffff;
    padding: 1.25rem;
    border: 1px solid lightgrey;
    margin: 0 1rem 1rem;
    border-radius: 4px;
    overflow-y: auto;

    h3 {
      margin-top: 0;
    }

    .button {
      margin: 1rem 0.5rem;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.refTable {
  width: 35vw;
}
