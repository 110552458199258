.logoUploader {
  display: flex;
  width: fit-content;
  gap: 6px;
  .hidden {
    opacity: 0;
    height: 0;
    width: 0;
    line-height: 0;
    overflow: hidden;
    padding: 0;
    margin: 0;
  }

  .logoButton {
    width: auto;
    height: 60px;
    cursor: pointer;
    img {
      height: 100%;
      width: auto;
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2), 0 3px 6px rgba(0, 0, 0, 0.25);
      border-radius: 4px;
      min-height: 60px;
      min-width: 50px;
    }
  }

  :global(.p-button-outlined.p-button-rounded) {
    &.dangerButton {
      color: #d32f2f;
      width: 24px;
      height: 24px;
      span {
        font-size: 12px;
      }
      &:hover {
        color: #d32f2f;
      }
    }
  }
}
