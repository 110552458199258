@import "../../../assets/scss/variables.scss";

.productList {

  .tableHeader {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .tableLabel {
      display: flex;
      flex-direction: row;
      margin: 0;

      .tableName {
        margin: 4px 15px 0 0;
        font-weight: 500;
      }
    }
  }

  .linkStyle {
    cursor: pointer;
    color: $light-blue;
    text-decoration: underline;
  }

  .linkStyle:hover {
    text-decoration: none;
  }

}

.dialog {
  width: $deleteDialog-width;
}

@media only screen and (max-width: $width-sm) {
  .productList {
    .tableHeader {
      .tableLabel {
        margin-bottom: 5px;
      }
    }
  }
}