.editImageForm {
  width: 600px;
  max-width: 90%;
  .images {
    width: 100%;
    .header {
      width: 100%;
      background: #f8f9fa;
      padding: 1rem;
      border: 1px solid #dee2e6;
      color: #495057;
      border-bottom: 0 none;
      border-top-right-radius: 3px;
      border-top-left-radius: 3px;
      position: relative;
      .hidden {
        opacity: 0;
        height: 0;
        width: 0;
        line-height: 0;
        overflow: hidden;
        padding: 0;
        margin: 0;
      }
      .progressBar {
        position: absolute;
        right: calc(50% - 135px);
        top: calc(50% - 4px);
        .progress {
          width: 250px;
          height: 7px;
          position: relative;
        }
      }
    }
    .content {
      width: 100%;
      background: #ffffff;
      padding: 2rem 1rem;
      border: 1px solid #dee2e6;
      color: #495057;
      border-bottom-right-radius: 3px;
      border-bottom-left-radius: 3px;
      .emptyTemplate {
        width: 100%;
        i {
          font-size: 3em;
          border-radius: 50%;
          background-color: var(--surface-b);
          color: var(--surface-d);
        }
        span {
          font-size: 1em;
          color: var(--text-color-secondary);
        }
      }
    }
  }
  .buttons {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    .button {
      margin: 14px 0;
    }
  }
}
