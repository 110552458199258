@import "../../../../assets/scss/variables";

.trialInfo {
  height: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;

  .trialForm {
    flex: 1;
    background: #ffffff;
    padding: 1.25rem;
    border: 1px solid lightgrey;
    margin: 0 1rem 1rem;
    border-radius: 4px;
    overflow-y: auto;

    button.calendarButton {
      width: 240px;
      height: 33px;
      background: #fff;
      border: 1px solid #ced4da;
      border-radius: 3px;
      text-align: start;
      color: #495057;
      padding: 5px 0 5px 5px;
      justify-content: flex-end;

      span:first-child {
        margin-right: 14px;
      }

      &:hover {
        background: white !important;
        border-color: #2196F3 !important;
      }
    }

    h3 {
      margin-top: 0;
    }

    .button {
      margin: 1rem 0.5rem;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .spinner {
    position: absolute;
    top: calc(50% - 19px);
    left: calc(50% - 19px);
    color: black;
    font-size: 30px
  }
}

.refTable {
  width: 35vw;
}

div.checkbox {
  padding-left: 1rem;
  margin-bottom: 0;
}

@media only screen and (max-width: $width-sm) {
  .trialInfo {
    .trialForm {
      .buttonsGroup {
        justify-content: space-between;
        padding-left: 0;
        margin-left: 0;
        button {
          padding: 0.5rem 0.8rem;
          &:first-child {
            margin-left: 0;
          }
        }

      }
    }

    div.checkbox {
      margin-left: 0;
    }
  }
}
