@import '../../../assets/scss/variables';

.menu {
  padding: 0 10px 10px;

  .menuItems {
    list-style: none;
    padding: 5px 0;
    margin: 0;
    color: rgba(255, 255, 255, 0.8);

    li {
      align-items: center;
      margin: 0 15px;
      cursor: pointer;
      border-radius: 4px;
      height: 35px;
    }

    li.active {
      font-weight: 700;
    }

    li:hover,
    li.active {
      background: rgba(255, 255, 255, 0.1);
    }

    li a:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.1);
    }

    a,
    a:visited {
      text-decoration: none;
      color: white;
      display: flex;
      height: 100%;
      align-items: center;
      padding: 10px;
    }
    a:active,
    a.active {
      font-weight: 700;
      background-color: rgba(255, 255, 255, 0.1);
      border-radius: 4px;
    }
  }
}
