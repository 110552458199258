@import '../../../../assets/scss/variables';

.plantList {
  img {
    height: 100%;
    width: auto;
  }
  .linkStyle {
    cursor: pointer;
    color: $light-blue;
    text-decoration: underline;
    border-style: none;
    background-color: rgba(117, 190, 218, 0);
  }

  .circleCentered {
    text-align: center;
  }

  .statusIcon {
    font-size: 0.75em;
    color: green;
  }

  .linkStyle:hover {
    text-decoration: none;
  }

  .tabFontSize {
    font-size: 1rem;
  }

  .tableHeader {
    display: flex;
    justify-content: space-between;

    .tableLabel {
      display: flex;
      flex-direction: row;
      margin: 0;

      button {
        margin-right: 8px;

        .cancelUpload {
          background: transparent;
        }
      }
    }
  }

  .customTableStyle {
    display: flex;
    flex-direction: column;
  }

  .plantThumb {
    margin: 0 5px;
    border-radius: 3px;
    height: 42px;
    object-fit: contain;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2), 0 3px 6px rgba(0, 0, 0, 0.25);
    &:hover {
      cursor: zoom-in;
    }
  }

  .footerMessage {
    color: $text-grey;
  }

  .pdfIcon {
    i {
      font-size: 1.7rem;
      color: grey;
    }
  }
}

@media only screen and (max-width: $width-xl) {
  .plantList {
    width: 81%;
  }
}

@media only screen and (max-width: $width-md) {
  .plantList {
    .tableHeader {
      flex-direction: column;

      .searchField {
        margin-top: 20px;
      }
    }

    .customTableStyle > div:nth-child(2),
    .customTableStyle > div:nth-child(3) {
      height: calc(100% - 55px) !important;
    }
  }
}

@media only screen and (max-width: $width-sm) {
  .plantList {
    .tableHeader {
      flex-direction: column;

      .tableLabel {
        .tabFontSize {
          font-size: 12px;
        }

        button {
          font-size: 12px;
          padding: 3px;

          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
  }
}
