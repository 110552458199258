@import "../../../../assets/scss/variables";

.cropInfo {
  height: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;

  .cropForm {
    flex: 1;
    background: #ffffff;
    padding: 1.25rem;
    border: 1px solid lightgrey;
    margin: 0 1rem 1rem;
    border-radius: 4px;
    overflow-y: auto;

    button.calendarButton {
      width: 165px;
      height: 33px;
      background: #fff;
      border: 1px solid #ced4da;
      border-radius: 3px;
      text-align: start;
      color: #495057 !important;
      padding: 5px 0 5px 5px;
      justify-content: flex-end;

      span:first-child {
        margin-right: 14px;
      }

      &:hover {
        background: white !important;
        border-color: #2196F3 !important;
      }
    }

    h3 {
      margin-top: 0;
    }

    .button {
      margin: 1rem 0.5rem;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.refTable {
  width: 35vw;
}

@media only screen and (max-width: $width-md) {
  .weekLabel {
    display: none !important;
  }
}
