.reportList {
  height: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;

  .reportForm {
    flex: 1;
    position: relative;
    background: #ffffff;
    border: 1px solid lightgrey;
    margin: 0 1rem 1rem;
    border-radius: 4px;
    canvas {
      height: calc(100vh - 130px);
    }

    .message {
      position: absolute;
      left: 1rem;
      right: 1rem;
      top: calc(50% - 25px);
    }
  }
}
