@import "../../../../assets/scss/variables";

.batchFormDialog {
  width: $formDialogWidth;
  max-width: $formDialogMaxWidth;

  .button {
    margin: 1rem 0.5rem;

    &:last-child {
      margin-right: 0;
    }
  }

  button.calendarButton {
    width: 165px;
    height: 33px;
    background: white;
    border: 1px solid #ced4da;
    border-radius: 3px;
    text-align: start;
    color: #495057 !important;
    padding: 5px 0 5px 5px;
    justify-content: flex-end;

    span:first-child {
      margin-right: 14px;
    }

    &:hover {
      background: white !important;
      border-color: #2196F3 !important;
    }
  }

  .noTopPadding {
    padding-top: 0;
  }

  .noRightPadding {
    padding-right: 0;
  }
}

@media only screen and (max-width: $width-xl) {
  .calendarButton {
    width: 165px !important;
  }
}

@media only screen and (max-width: $width-lg) {
  .calendarButton {
    width: 175px !important;
  }
}

@media only screen and (max-width: $width-md) {
  .calendarButton {
    width: 150px !important;
  }
}
