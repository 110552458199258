@import "../../assets/scss/variables";

.dashboard {
  width: 100%;
  color: $dark-blue;
  background-size: cover;
  overflow: auto;

  .alert {
    text-align: center;
    padding: 15px 0;
  }

  .spinnerStyle {
    position: absolute;
    top: calc(50% - 19px);
    left: calc(50% - 19px);
    color: black;
    font-size: 30px;
  }
;

  .hideElement {
    visibility: hidden;
  }

  .noClickable {
    pointer-events: none;
    opacity: 0.7;
  }

  .noClickableCalendar {
    pointer-events: none;
  }

  .calendarIcons {
    margin-right: 20px;
    font-size: 1.5rem;
    color: blue;
  }

  .opacityIcons {
    opacity: 0.5;
  }

  .taskHeader {
    width: 100%;
    background: rgba(255, 255, 255, 0.8);
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    outline: 1px solid $light-grey;
    outline-offset: -1px;
    font-size: 18px;
    color: $highlightTextColor;

    .headerTop {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .leftHeader {
        flex: 2;
        text-decoration: none;
        color: $highlightTextColor;

        div {
          padding: 1rem 0 1rem 1rem;
        }

        &:hover {
          background: #e9ecef;
          border-top-left-radius: 10px;
          cursor: pointer;
        }
      }

      .rightHeader {
        flex: 1;
        display: flex;
        justify-content: center;
      }
    }

    .headerBottom {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      border-top: 1px solid $light-grey;
      padding: 0.5rem 0 0.5rem 1rem;

      .dropdownWrapper > div {
        width: 200px;
      }

      .loading > div {
        background: transparent;
        border: 1px solid transparent;
      }
    }

  }

  .dashboardContent {
    display: flex;
    width: 100%;
  }

  .caption {
    display: flex;
    border-bottom: 1px solid $light-grey;
    padding: 5px 15px 15px 0;

    span {
      flex-basis: 50%;
    }
  }

  .panelFooter {
    display: flex;
    justify-content: flex-end;
    padding: 10px 10px 0 0;
  }

  .left {
    width: 50%;
  }

  .right {
    width: 50%
  }

  .panel {
    margin: 20px;
    font-weight: 600;

    .p-panel-header {
      span {
        font-size: 18px;
      }
    }

    .p-toggleable-content {
      .p-panel-content {
        border-radius: 10px;
      }
    }
  }

  .calendarPanel {
    border-radius: 10px;

    .calendarTitleBlock {
      display: flex;
      align-items: center;

      .calendarTitle {
        font-size: 18px;
        color: $highlightTextColor;
      }
    }

    a {
      display: flex;
      justify-content: space-between;
      width: 100%;
      padding: 1rem;
      text-decoration: none;

      &:hover {
        background: #e9ecef;
        border-radius: 10px;
      }
    }
  }

  .calendarPanel > div > div {
    border: 1px solid $light-grey;
    border-radius: 10px;
    padding: 0;
  }

  .panel .p-panel-content {
    background: rgba(255, 255, 255, 0.8) !important;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
  }

  .panel .p-panel-header {
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
  }
}

@media only screen and (max-width: $width-md) {
  .dashboard {
    width: 100vw;

    .dashboardContent {
      flex-direction: column;
      justify-content: center;

      .left, .right {
        width: 100%;
      }
    }
  }
}