@import '../../../assets/scss/variables';

.reportFormDialog {
  width: 300px;
  max-width: $formDialogMaxWidth;
  height: 300px;

  .button {
    margin: 1rem 0.5rem;

    &:last-child {
      margin-right: 0;
    }
  }

  div.checkbox {
    padding-left: 1rem;
    margin-bottom: 0;
  }
}
