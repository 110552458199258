@import "../../../../assets/scss/variables";


.trialCropList {

  .linkStyle {
    cursor: pointer;
    color: $light-blue;
    text-decoration: underline;
    border: none;
    background: none;
  }

  .linkStyle:hover {
    text-decoration: none;
  }

  .tableHeader {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .tableLabel {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;

      button {
        margin-right: 5px;
      }

      .tableName {
        margin: 4px 15px 0 0;
        font-weight: 500;
      }
    }
  }

  .footerMessage {
    color: $text-grey;
  }

  .exportButton {
    height: fit-content;
    margin-left: 5px;
  }

  .customTableStyle {
    display: flex;
    flex-direction: column;
  }

  .errorsTable {
    max-height: 90vh;
    overflow: auto;
    .infoBlock {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 10px;
      .infoMessage {
        font-weight: bold;
      }
    }
    table {
      border-collapse: collapse;
      tr {
        width: 100%;
        td, th {
          border: 1px solid red;
          padding: 3px;
        }
      }
    }
  }
}

@media only screen and (max-width: $width-xl) {
  .trialCropList {
    width: 81%;
  }
}

@media only screen and (max-width: $width-md) {
  .trialCropList {
    .tableHeader {
      flex-direction: column;

      .tableLabel {
        button {
          margin-bottom: 10px;
          padding: 0.5rem;
        }
      }
    }

    .customTableStyle {
      height: calc(100% - 130px) !important;
    }

    .customTableStyle > div:nth-child(2),
    .customTableStyle > div:nth-child(3) {
      height: calc(100% - 60px) !important;
    }
  }
}