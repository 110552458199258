@import '../../../assets/scss/variables';

.zoomGallery {
  box-sizing: border-box;
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  position: fixed;
  left: 0;
  top: 0;
  transition-duration: 0.2s;
  width: 100%;
  z-index: 1005;

  .galleryContainer {
    display: flex;
    flex-direction: column;
    width: 90%;
    max-width: 500px;
    height: fit-content;
    z-index: 1100;

    .imageMenu {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      background-color: rgba(0, 0, 0, 0.6);
      padding: 10px 0;

      .imageItem {
        width: 70px;
        height: 70px;
        border: 3px solid white;
        margin-right: 15px;

        &:hover {
          cursor: pointer;
        }

        img {
          width: 100%;
          height: auto;
        }
      }
    }
  }

  .active {
    border: 3px solid $dangerColor !important;
  }
}
