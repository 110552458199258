@import '../../../assets/scss/variables';

.employeeFormDialog {
  width: $formDialogWidth;
  max-width: $formDialogMaxWidth;

  .calendarButton {
    width: 240px !important;
    height: 33px;
    background: white;
    border: 1px solid #ced4da !important;
    border-radius: 3px !important;
    text-align: start;
    color: #495057 !important;
    padding: 5px 0 5px 5px;
    justify-content: flex-end;

    span:first-child {
      margin-right: 14px;
    }
  }

  .calendarButton:hover {
    background: white !important;
    border-color: #2196F3 !important;
  }

  .button {
    margin: 1rem 0.5rem;

    &:last-child {
      margin-right: 0;
    }
  }

  .hidden {
    visibility: hidden;
  }
;

  .checkbox {
    padding-left: 1rem;
    margin-bottom: 0 !important;
  }

  .employeeImage {
    margin: 0 5px;
    border-radius: 5px;
    object-fit: contain;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2), 0 3px 6px rgba(0, 0, 0, 0.25);
  }
}

@media only screen and (max-width: $width-md) {
  .employeeFormDialog {
    .checkbox {
      margin-left: 0;
    }
  }
}
