@import "../../../../assets/scss/variables";

.logList {

  .hidden {
    visibility: hidden;
  }

  .visible {
    visibility: visible;
  }

  .dialog {
    width: 450px;

    .dialogIcon {
      font-size: 3rem;
    }
  }

  .groupByName {
    font-weight: bold;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .checkboxEditor {
    width: 66px;
    border: 1px solid #ced4da;
    height: 33px;
    padding: 5px;
    border-radius: 3px;
  }

  .timeEditor {

    input {
      width: 100px;
      border: 1px solid #ced4da;
      height: 33px;
      padding: 5px;
      border-radius: 3px;
    }
  }

  .dropdownEditor {
    width: 100px;
  }

  .multiSelectEditor {
    width: 100px;
  }

  .customTableStyle {
    display: flex;
    flex-direction: column;
  }
}

.progressBar {
  height: 7px !important;
  width: 25%;
  margin: 0 auto;
}

.tlHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  h3 {
    margin: 8px;
  }

  .multiselect {
    width: 15rem;
    height: fit-content;
  }

  .groupByDropdown {
    width: 10rem;
  }

  .exportButton {
    height: fit-content;
  }
}

@media only screen and (max-width: $width-md) {
  .logList {
    .customTableStyle {
      height: calc(100% - 120px) !important;
    }

    .customTableStyle > div:nth-child(2),
    .customTableStyle > div:nth-child(3) {
      height: calc(100% - 55px) !important;
    }
  }
}
