@import "../../../assets/scss/variables";

.contactUsForm {
  width: 100%;
  overflow: auto;
  font-family: "Helvetica Regular", "Arial", sans-serif;
  color: $supportColor;

  input, span {
    color: $supportColor;
  }

  .submitButton {
    span {
      color: #FFFFFF;
    }
  }

  form {
    width: 90%;
    max-width: 540px;
    border: 1px solid white;
    border-radius: 5px;
    margin: 20px auto;
    padding: 9px 35px;
  }

  .leftPadding {
    padding-left: 9px;
  }

  .leftOffset {
    margin-left: 33.33333333%;
  }

  h2 {
    margin: 15px 0 0;
    font-size: 30px;
    color: $supportHeaderColor
  }

  h4 {
    color: $supportHeaderColor;
    font-size: 20px;
  }
}

@media only screen and (max-width: $width-md) {
  .contactUsForm {
    width: 100vw;
  }
}

@media only screen and (max-width: $width-sm) {
  .contactUsForm {
    .leftOffset {
      margin-left: 0;
    }

    .leftPadding {
      padding-left: 0;
    }
  }
}