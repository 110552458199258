.addNewFieldDialog {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px;

  .dialogElement {
    margin: 5px 0;
  }

  .progressBar {
    width: 200px;
    height: 7px;
  }

  .uploaderHeader {
    background-color: transparent;
    display: flex;
    align-items: center;
  }

  .preview {
    width: 67%;
  }

  .fieldImage {
    width: 50%;
    margin: 0 5px;
    border-radius: 5px;
    object-fit: contain;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2), 0 3px 6px rgba(0, 0, 0, 0.25);
  }

  .emptyAreaIcon {
    font-size: 3em;
    border-radius: 50%;
    background-color: var(--surface-b);
    color: var(--surface-d);
  }

  .emptyAreaText {
    font-size: 1em;
    color: var(--text-color-secondary)
  }

  .errorBorder {
    border: 1px solid red;
    box-shadow: none
  }
  .errorMessage {
    color: red
  }
}