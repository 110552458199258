.previewContainer {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  background-color: rgba(0, 0, 0, 0.6);
  transition-duration: 0.2s;

  img {
    height: 80% !important;
    width: auto !important;
    z-index: 100;
  }
}